<template>
  <div class="contnet">
    <div v-if="show_page">
      <p>微信授权</p>
      <p class="authBtn" @click="clickAuth">点击授权</p>
      <p class="authBtn" @click="clickShare">点击配置分享</p>
    </div>
    <div v-else>
      <p class="tips">请使用微信浏览器打开</p>
    </div>
  </div>
</template>

<!-- <script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script> -->
<script>
import tool from '@/utils/tools.js'
import wx from 'weixin-js-sdk'
import { wxshareInfo } from '@/api/edu_api.js'

export default {
  data() {
    return {
      // 授权回调地址：必传
      redirect_url: this.$route.query.redirect || '',
      show_page: false
    }
  },
  created() {
    if (tool.isWechatBrowser()) {
      this.show_page = true
    }
  },
  methods: {
    clickAuth() {
      const origin = window.location.origin
      const app_redirect = encodeURIComponent(this.redirect_url)
      var auth_redirect = `${origin}/wechatAuthResult?auth_redirect=${app_redirect}`
      auth_redirect = encodeURIComponent(auth_redirect)
      const appid = 'wx3ca1f327be0effb6'
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${auth_redirect}&response_type=code&scope=snsapi_userinfo&state=success#wechat_redirect`
      window.location.href = url
    },
    clickShare() {
      this.wxShareConfig()
    },
    // 获取微信配置
    wxShareConfig() {
      var info = {
        url: window.location.href.split('#')[0]
      }
      wxshareInfo(info).then(res => {
        const wxconf = res || {}
        this.shareWxConfig = wxconf
        this.confiShareWx()
      })
    },
    // 配置微信里分享
    confiShareWx() {
      // config
      const threadUrl = decodeURIComponent(this.shareWxConfig.url) // 解码
      const appid = this.shareWxConfig.appId
      const nonceStr = this.shareWxConfig.nonceStr
      const signature = this.shareWxConfig.signature
      const timestamp = this.shareWxConfig.timestamp
      // 修改debug
      // const getUid = Cookies.get('uid')
      // var dedebug = false
      // if (getUid == '146768' || getUid == '146769') {
      //   dedebug = true
      // }
      const dedebug = true
      //       appId	String	wx3ca1f327be0effb6
      // nonceStr	String	Wm2cqUGqHnO0A8dz
      // timestamp	Integer	1724928943
      // url	String	https://qm310.kinglake.cloud/home?lesson_id=16
      // signature	String	d781db055b5227f095d3dd444e074785a570f72a
      // rawString	String	jsapi_ticket=&noncestr=Wm2cqUGqHnO0A8dz&timestamp=1724928943&url=https://qm310.kinglake.cloud/home?lesson_id=16

      wx.config({
        debug: dedebug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appid, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名
        jsApiList: [
          'onMenuShareAppMessage',
          'updateTimelineShareData', // 朋友圈和QQ空间
          'updateAppMessageShareData' // 分享给朋友和QQ
        ]
      })
      // 配置数据
      const that = this
      wx.ready(function() {
        wx.checkJsApi({
          jsApiList: [
            'onMenuShareAppMessage',
            'updateTimelineShareData', // 朋友圈和QQ空间
            'updateAppMessageShareData' // 分享给朋友和QQ
          ],
          success: function(res) {
            that.configShareDatas(threadUrl)
          }
        })
      })
    },
    configShareDatas(url) {
      const title = 'BMW长沙可持续智能化展厅'
      const desc = '欢迎光临-BMW长沙可持续智能化展厅'
      const imgUrl = 'https://newoss.zhulong.com/gift/202305/08/35/1219355jso51fzgcmi2idd.jpg'
      // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
      wx.updateAppMessageShareData({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function() {
          // 设置成功
        }
      })
      // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
      wx.ready(function() { // 需在用户可能点击分享按钮前就先调用
        wx.updateTimelineShareData({
          title: title, // 分享标题
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: imgUrl, // 分享图标
          success: function() {
            // 设置成功
          }
        })
      })
      // 获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareAppMessage({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        type: 'link', // 分享类型,music、video或link，不填默认为link
        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
        success: function() {
          // 用户点击了分享后执行的回调函数
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.contnet {
  // width: 700px;
  margin: 0 auto;
}
.authBtn {
  margin: 25% 10% 0;
  height: 35px;
  line-height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  background-color: #ee2e2e;
}
.tips {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}
</style>
