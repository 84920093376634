import wx from "weixin-js-sdk";
import Cookie from 'js-cookie'


var ua = window.navigator.userAgent.toLowerCase();
var WechatState = { isWechat: false, isWechatMiniprogram: false }
// 该js 判断是否是在微信、微信小程序里，区分更准确
if (ua.match(/MicroMessenger/i) == "micromessenger") {
  // 微信环境下
  WechatState.isWechat = true
  // 下面判断 是否小程序
  if (ua.match(/miniProgram/i) == 'miniprogram') {
    // 小程序环境下逻辑
    console.log('小程序环境下')
    WechatState.isWechatMiniprogram = true
  } else {
    // 非小程序环境下逻辑
    console.log('非小程序环境下')
    WechatState.isWechatMiniprogram = false
  }
  wx.miniProgram.getEnv(function (res) {
    if (res.miniprogram) {
      // 小程序环境下逻辑
      console.log("小程序环境下");
      WechatState.isWechatMiniprogram = true
    } else {
      // 非小程序环境下逻辑
      console.log("非小程序环境下");
      WechatState.isWechatMiniprogram = false
    }
  });
}

// 检测微信小程序内是否登录
WechatState.checkWechatMPLogin = function() {
  if (WechatState.isWechatMiniprogram) {
    let uid = Cookie.get('uid') || ''
    if (!uid) {
      const url = '/pages/login/login'
      wx.miniProgram.navigateTo({
        url: url
      })
      return false
    }
  }
  return true
}

export default WechatState;
